import axiosService from "@services/axiosService";
import { IUserCredentials, IAuthToken, Profile } from "@models/auth";
import { IAuthService } from "@services/interface/IAuthService";
import { IDataResponse } from "@services/interface/IDataResponse";
import { BaseViewModel } from "@/modelviews/BaseViewModel";
import "reflect-metadata";
import { injectable } from "inversify";
import { IDepartmentEmployee } from "@/models/setting/Department";
import { normalizeDepartmentEmployee } from "@services/nomalizeModel/normalizeDepartmentEmployee";

@injectable()
export class BranchService {
  private prefix: string;

  constructor() {
    this.prefix = "/labs";
  }

  async getBranch(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/branches`, {
      params: params,
    });
    return response;
  }

  async getBranchOptimize(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(
      `${this.prefix}/branches/select-options`,
      {
        params: params,
      }
    );
    return response;
  }

  async getDepartmentEmployee(
    params: any
  ): Promise<IDataResponse<IDepartmentEmployee[]>> {
    const response = await axiosService.get(`${this.prefix}/branches`, {
      params: params,
    });
    if (response.data) {
      response.data = normalizeDepartmentEmployee(response.data);
    }
    return response;
  }

  async updateBranch(
    params: any,
    laboid: number,
    braId: number
  ): Promise<IDataResponse<any>> {
    const response = await axiosService.put(
      `${this.prefix}/branches/${braId}`,
      params
    );
    return response;
  }

  async createBranch(params: any, laboid: number) {
    const response = await axiosService.post(`${this.prefix}/branches`, params);
    return response;
  }

  async deleteBranch(
    laboid: number,
    braId: number
  ): Promise<IDataResponse<any>> {
    const response = await axiosService.delete(
      `${this.prefix}/branches/${braId}`
    );
    return response;
  }

  async sort(ids: Object): Promise<IDataResponse<any>> {
    const response = await axiosService.put(
      `${this.prefix}/branches/sort-order`,
      ids
    );
    return response;
  }
}
