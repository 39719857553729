
import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import { injectable } from "inversify";
import "reflect-metadata";

export interface ISettingEVMMaintenanceService {
  getSettingEVMMaintenance(): Promise<IDataResponse<any>>;  
}

@injectable()
export class SettingEVMMaintenanceService implements ISettingEVMMaintenanceService {
  private prefix: string;
  constructor() {
    this.prefix = '/system/maintenance-info/evm';
  }
  async getSettingEVMMaintenance(): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}`);    
    return response;
  }
}