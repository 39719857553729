import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import { injectable } from "inversify";
import "reflect-metadata";

export interface IImportCSVService {
  importCSV(file: any): Promise<IDataResponse<any>>;
  getClinicsTemp(params: any): Promise<IDataResponse<any>>;
  searchClinic(id: string): Promise<IDataResponse<any>>;
  addClinicExist(data: any): Promise<IDataResponse<any>>;
  addNewClinic(data: any): Promise<IDataResponse<any>>;
}

@injectable()
export class ImportCSVService implements IImportCSVService {
  private prefix: string;
  constructor() {
    this.prefix = "/import";
  }
  
  async importCSV(file: any): Promise<IDataResponse<any>> {

    const response = await axiosService.post(
      `${this.prefix}/import-csv`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  }

  async getClinicsTemp(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/clinics-temp`, {
      params,
    });
    return response;
  }

  async searchClinic(id: string): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/search-clinic/${id}`);
    return response;
  }

  async addClinicExist(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}/add-clinic-exist`, data );
    return response;
  }

  async addNewClinic(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}/add-new-clinic`, data );
    return response;
  }
}
