import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@di/types";
import { type ISettingEmployeeService } from "@/datasource/services/setting/employee";
import {
  IEmployee,
  IEmployeeUpdate,
  IGetAllEmployeeParams,
  IRetireEmployeeParams,
} from "@/models/setting/Employee";
import { IPagination } from "@/models/Pagination";

export interface ISettingEmployeeRepository {
  updateEmployee(
    params: IEmployeeUpdate,
    id: any
  ): Promise<IDataResponse<IEmployee>>;
  updateEmployeeTargetPoint(
    id: number,
    data: any
  ): Promise<IDataResponse<IEmployee>>;
  getEmployee(id: any): Promise<IDataResponse<IEmployee>>;
  createEmployee(params: IEmployeeUpdate): Promise<IDataResponse<IEmployee>>;
  deleteEmployee(id: any): Promise<IDataResponse<any>>;
  retireEmployee(
    params: IRetireEmployeeParams,
    id: any
  ): Promise<IDataResponse<any>>;
  getAllEmployee(
    params: IGetAllEmployeeParams
  ): Promise<IDataResponse<{ data: IEmployee[]; pagination: IPagination }>>;
  recoverEmployee(
    params: IRetireEmployeeParams,
    id: any
  ): Promise<IDataResponse<any>>;
  updatePolicy(id: any, params: any): Promise<IDataResponse<any>>;
  getEmployeeTask(params: any): Promise<IDataResponse<any>>;
  getEmployeeTaskTimeLine(params: any): Promise<IDataResponse<any>>;
  getEmployeeTaskActualHours(params: any): Promise<IDataResponse<any>>;
  retireEmployee(params: IRetireEmployeeParams, id: any): Promise<IDataResponse<any>>;
  getAllEmployee(params: IGetAllEmployeeParams): Promise<IDataResponse<{data: IEmployee[]; pagination: IPagination}>>;
  recoverEmployee(params: IRetireEmployeeParams, id: any): Promise<IDataResponse<any>>;
  disabledEdit(id: any): Promise<IDataResponse<any>>;
  logOut(id: any): Promise<IDataResponse<any>>;
  getOriginalLog(id: any): Promise<IDataResponse<any>>;
  updateLog(data: any): Promise<IDataResponse<any>>;
  registerNewQRToken(id: string): Promise<IDataResponse<any>>;
}
@injectable()
export class SettingEmployeeRepository implements ISettingEmployeeRepository {
  constructor(
    @inject(TYPES.SettingEmployeeService)
    private settingEmployeeService: ISettingEmployeeService
  ) {}
  
  async getOriginalLog(id: any): Promise<IDataResponse<IEmployee>> {
    return await this.settingEmployeeService.getOriginalLog(id);
  }

  async updateLog(data: any): Promise<IDataResponse<IEmployee>> {
    return await this.settingEmployeeService.updateLog(data);
  }

  async getEmployeeTaskActualHours(params: any): Promise<IDataResponse<IEmployee>> {
    return await this.settingEmployeeService.getEmployeeTaskActualHours(params);
  }


  async getEmployeeTaskTimeLine(params: any): Promise<IDataResponse<IEmployee>> {
    return await this.settingEmployeeService.getEmployeeTaskTimeLine(params);
  }

  async getEmployeeTask(params: any): Promise<IDataResponse<IEmployee>> {
    return await this.settingEmployeeService.getEmployeeTask(params);
  }

  async updatePolicy(id: any, params: any): Promise<IDataResponse<IEmployee>> {
    return await this.settingEmployeeService.updatePolicy(id, params);
  }

  async disabledEdit(id: any): Promise<IDataResponse<any>> {
    return await this.settingEmployeeService.disabledEdit(id);
  }
  async logOut(id: any): Promise<IDataResponse<IEmployee>> {
    return await this.settingEmployeeService.logOut(id);
  }

  async updateEmployee(params: IEmployeeUpdate, id: any): Promise<IDataResponse<IEmployee>> {
    let res = await this.settingEmployeeService.updateEmployee(params, id);
    return res;
  }
  async updateEmployeeTargetPoint(
    id: number,
    data: any
  ): Promise<IDataResponse<IEmployee>> {
    let res = await this.settingEmployeeService.updateEmployeeTargetPoint(
      id,
      data
    );
    return res;
  }

  async getEmployee(id: any): Promise<IDataResponse<IEmployee>> {
    let res = await this.settingEmployeeService.getEmployee(id);
    return res;
  }
  async createEmployee(
    params: IEmployeeUpdate
  ): Promise<IDataResponse<IEmployee>> {
    let res = await this.settingEmployeeService.createEmployee(params);
    return res;
  }
  async deleteEmployee(id: any): Promise<IDataResponse<any>> {
    let res = await this.settingEmployeeService.deleteEmployee(id);
    return res;
  }
  async retireEmployee(
    params: IRetireEmployeeParams,
    id: any
  ): Promise<IDataResponse<any>> {
    let res = await this.settingEmployeeService.retireEmployee(params, id);
    return res;
  }
  async getAllEmployee(
    params: IGetAllEmployeeParams
  ): Promise<IDataResponse<{ data: IEmployee[]; pagination: IPagination }>> {
    let res = await this.settingEmployeeService.getAllEmployee(params);
    return res;
  }
  async recoverEmployee(
    params: IRetireEmployeeParams,
    id: any
  ): Promise<IDataResponse<any>> {
    let res = await this.settingEmployeeService.recoverEmployee(params, id);
    return res;
  }

  async registerNewQRToken(id: string): Promise<IDataResponse<IEmployee>> {
    return await this.settingEmployeeService.registerNewQRToken(id);
  }
}
