import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";

export interface ISettingProcessService {
  fetchAll(params: any): Promise<IDataResponse<any>>;
  fetch(id: number, params: any): Promise<IDataResponse<any>>;
  create(data: any): Promise<IDataResponse<any>>;
  update(id: number, data: any): Promise<IDataResponse<any>>;
  updateField(id: number, data: any): Promise<IDataResponse<any>>;
  delete(id: number): Promise<IDataResponse<any>>;
  sortOrder(data: any): Promise<IDataResponse<any>>;

  createCategory(data: any): Promise<IDataResponse<any>>;
  fetchCategories(params: any): Promise<IDataResponse<any>>;

  //order process
  createTasks(data: any): Promise<IDataResponse<any>>;
  updatFavoriteProcess(data: any): Promise<IDataResponse<any>>;
  updateCategories(data: any): Promise<IDataResponse<any>>;
  checkDeleteCategory(id: number): Promise<IDataResponse<any>>;
}

@injectable()
export class SettingProcessService implements ISettingProcessService {
  private prefix: string;
  private prefixCategory: string;

  constructor() {
    this.prefix = "/labs/processes";
    this.prefixCategory = "/labs/process-categories";
  }

  async updatFavoriteProcess(data: any): Promise<IDataResponse<any>> {
    return await axiosService.put(`${this.prefix}/fav-processes`, data);
  }

  async createTasks(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}/tasks`, data);
    return response;
  }

  async fetchAll(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}`, {
      params: params,
    });
    return response;
  }

  async sortOrder(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/sort-order`, data);
    return response;
  }

  async fetch(id: number, params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/${id}`, {
      params: params,
    });
    return response;
  }

  async update(id: number, data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/${id}`, data);
    return response;
  }

  async updateField(id: number, data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/${id}/from-list`, data);
    return response;
  }

  async delete(id: number): Promise<IDataResponse<any>> {
    const response = await axiosService.delete(`${this.prefix}/${id}`);
    return response;
  }

  async create(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}`, data);
    return response;
  }

  async createCategory(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefixCategory}`, data);
    return response;
  }

  async fetchCategories(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefixCategory}`, {
      params: params,
    });
    return response;
  }

  async updateCategories(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefixCategory}/bulk-upsert-categories`, data);
    return response;
  }

  async checkDeleteCategory(id: number): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefixCategory}/${id}/check-can-delete`);
    return response;
  }
}
